var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0"},[_c('v-form',{ref:"formRef",on:{"submit":function($event){$event.preventDefault();return _setup.onGenerateReportFormSubmitted.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c(_setup.LabelledField,{attrs:{"label":"Block / Plot Number","required":""}},[_c('v-autocomplete',{staticClass:"text-field-background",attrs:{"items":_setup.userProperties,"item-text":_setup.formatPropertyForDropdown,"item-value":"prn","outlined":"","dense":"","required":"","loading":_setup.fetchingAllProperties,"disabled":_setup.fetchingAllProperties,"hide-details":"auto","rules":[_setup.fieldRequired]},model:{value:(_vm.models.prn),callback:function ($$v) {_vm.$set(_vm.models, "prn", $$v)},expression:"models.prn"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c(_setup.LabelledField,{attrs:{"label":"From Date","required":""}},[_c(_setup.DatePicker,{attrs:{"rules":[_setup.fieldRequired, _setup.dateLessThanToDate],"textFieldProps":{
                        outlined: true,
                        dense: true,
                        class: 'text-field-background',
                        'hide-details': 'auto'
                    }},model:{value:(_vm.models.fromDate),callback:function ($$v) {_vm.$set(_vm.models, "fromDate", $$v)},expression:"models.fromDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c(_setup.LabelledField,{attrs:{"label":"To Date","required":""}},[_c(_setup.DatePicker,{attrs:{"rules":[_setup.fieldRequired, _setup.dateGreaterThanFromDate],"textFieldProps":{
                        outlined: true,
                        dense: true,
                        class: 'text-field-background',
                        'hide-details': 'auto'
                    }},model:{value:(_vm.models.toDate),callback:function ($$v) {_vm.$set(_vm.models, "toDate", $$v)},expression:"models.toDate"}})],1)],1)],1),_c('v-row',_vm._l((_setup.additionalDetails),function(value,title){return _c('v-col',{attrs:{"cols":"12","md":"4"}},[(_setup.fetchingAllProperties)?_c('v-skeleton-loader',{staticClass:"skeleton-fill-width",attrs:{"type":"button"}}):_c(_setup.LabelledField,{attrs:{"label":title}},[_c('v-text-field',{staticClass:"text-field-background",attrs:{"outlined":"","dense":"","hide-details":"","value":value,"disabled":""}})],1)],1)}),1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"text-none",attrs:{"depressed":"","color":"secondary","type":"submit","loading":_vm.generatingReport}},[_vm._v(" Generate Report "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-magnify")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }