<script setup>
import PropertyRateStatementTable from "./PropertyRateStatementTable.vue";
import GenerateReportSection from "./GenerateReportSection.vue";
import Pagination from "@/components/utils/Pagination.vue";
import LoadingArea from "@/components/utils/LoadingArea";
import ViewPdf from "@/components/utils/ViewPdf.vue";
import { useGeneratePropertyRateStatement } from "../../properties_business_logic";
import { convertDateStringToDMY } from "@/utils/functions";
import { useDownloadFile } from "@/utils/vue_helpers";
import { computed, ref } from "vue";
import JsonCsv from "vue-json-csv";
import { report } from "process";

const { reportData, statementData, fetchPropertyRateStatement, csvData } =
  useGeneratePropertyRateStatement();

async function onGenerateReport() {
  await fetchPropertyRateStatement();
  // await fetchPropertyRateCsv()
}

function onPaginationClicked() {}

const pdfOpen = ref(false);
const pdfUrl = ref(null);
const pdfUrlNotEmpty = computed(() => {
  return !!pdfUrl;
});

const { downloadFile } = useDownloadFile();
function onDownloadPdfClicked() {
  downloadFile({
    propertyRateData: {
      prn: reportData.prn,
      fromDate: reportData.fromDate,
      toDate: reportData.toDate,
      county: process.env.VUE_APP_COUNTY,
    },
  });
}
</script>

<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="auto" class="title primary--text">
        Property Rate Statement
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <GenerateReportSection
          :models="reportData"
          :generatingReport="statementData.fetchingData"
          @generateReport="onGenerateReport"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-divider class="primary" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <LoadingArea :loading="statementData.fetchingData" class="mb-4">
          <v-row
            v-if="statementData.fetchedDataNotEmpty"
            justify="space-between"
          >
            <v-col cols="auto">
              <v-btn
                depressed
                class="blue text-none white--text"
                @click="onDownloadPdfClicked"
              >
                Download PDF
                <v-icon right> mdi-download-circle </v-icon>
              </v-btn>
            </v-col>

            <v-col v-if="false" cols="auto">
              <JsonCsv
                :header="csvData.title"
                :title="csvData.title"
                :data="csvData.data"
                :fields="csvData.fields"
                :labels="csvData.labels"
                name="Property Rate Statement.csv"
              >
                <v-btn
                  depressed
                  class="success text-none"
                  :disabled="csvData.fetchingCsvData"
                >
                  Export CSV

                  <v-progress-circular
                    v-if="csvData.fetchingCsvData"
                    indeterminate
                    class="ml-2"
                    color="success"
                    size="16"
                    width="2"
                  />
                </v-btn>
              </JsonCsv>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="auto" class="title text-decoration-underline">
              Property Rate Statement
            </v-col>
          </v-row>

          <v-row justify="center" class="mb-6">
            <v-col cols="auto" class="text-body-2">
              Report period From:
              <span class="font-weight-bold">{{
                convertDateStringToDMY(reportData.fromDate)
              }}</span>
              To:
              <span class="font-weight-bold"
                >{{ convertDateStringToDMY(reportData.toDate) }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <PropertyRateStatementTable
                :statementItems="statementData.fetchedData"
                :loading="statementData.fetchingData"
              />
            </v-col>
          </v-row>

          <v-row justify="end">
            <v-col cols="auto">
              <Pagination
                :totalNumberOfItems="statementData.paginationData.total"
                :itemsPerPage="statementData.paginationData.perPage"
                :currentPage="statementData.paginationData.currentPage"
                @paginationClicked="onPaginationClicked"
              />
            </v-col>
          </v-row>
        </LoadingArea>
      </v-col>
    </v-row>

    <ViewPdf
      v-if="pdfUrlNotEmpty"
      :open.sync="pdfOpen"
      :url="pdfUrl"
      title="Property Rate Statement"
    />
  </v-container>
</template>
