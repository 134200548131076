<script setup>
import { arrayNotEmpty, convertDateStringToDMY, getFormattedMoney } from '@/utils/functions'
import { computed } from 'vue';

const props = defineProps({
    statementItems: {
        type: Array,
        default: null
    },
    loading: {
        type: Boolean,
        default: false
    }
})

const statementItemsNotEmpty = computed(() => {
    return arrayNotEmpty(props.statementItems)
})

function getTransactionDate(transactionDate) {
    const date = convertDateStringToDMY(transactionDate)
    const time = new Date(transactionDate).toLocaleTimeString('en-GB')
    return `${date} ${time}`
}
</script>

<template>
    <v-simple-table dense>
        <template #default>
            <thead>
                <tr class="text-caption">
                    <th class="text-left">Transaction Date</th>
                    <th class="text-left">Reference Number</th>
                    <th class="text-left">Land Rates Arrears</th>
                    <th class="text-left">Ground Rent Arrears</th>
                    <th class="text-left">Accumulated Penalty</th>
                    <th class="text-left">Annual Rates</th>
                    <th class="text-left">Annual Ground Rent</th>
                    <!-- <th class="text-left">Other Charges</th> -->
                    <th class="text-left">Other Charges Arrears</th>
                    <th class="text-left">Receipt Amount</th>
                    <th class="text-left">Adjustment </th>
                    <th class="text-left">Waiver</th>
                    <th class="text-left">Current Balance</th>
                    <th class="text-left">Receipt Number</th>
                </tr>
            </thead>

            <tbody>
                <template v-if="statementItemsNotEmpty">
                    <tr v-for="statementItem in statementItems" :key="statementItem.id">
                        <td class="text-caption">{{ getTransactionDate(statementItem.reference_date) }}</td>
                        <td class="text-caption">{{ statementItem.reference_no }}</td>
                        <td class="text-caption">{{ getFormattedMoney(statementItem.annual_rates_arrears) }}</td>
                        <td class="text-caption">{{ getFormattedMoney(statementItem.ground_rent_arrears) }}</td>
                        <td class="text-caption">{{ getFormattedMoney(statementItem.accumulated_penalty) }}</td>
                        <td class="text-caption">{{ getFormattedMoney(statementItem.annual_rates) }}</td>
                        <td class="text-caption">{{ getFormattedMoney(statementItem.ground_rent) }}</td>
                        <!-- <td class="text-caption">{{ getFormattedMoney(statementItem.other_charges) }}</td> -->
                        <td class="text-caption">{{ getFormattedMoney(statementItem.other_charges_arrears) }}</td>
                        <td class="text-caption">{{ getFormattedMoney(statementItem.receipt_amount) }}</td>
                        <td class="text-caption">{{ getFormattedMoney(statementItem.adjustment) }}</td>
                        <td class="text-caption">{{ getFormattedMoney(statementItem.waiver) }}</td>
                        <td class="text-caption">{{ getFormattedMoney(statementItem.current_balance) }}</td>
                        <td class="text-caption">{{ statementItem.receipt_no }}</td>
                    </tr>
                </template>
                <tr v-else>
                    <td colspan="14" class="text-center py-4">
                        {{ loading ? 'Loading' : 'No Statements' }}
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>