<script setup>
import LabelledField from '@/components/utils/LabelledField'
import DatePicker from "@/components/inputs/DatePicker.vue";
import { useFetchAllUserProperties } from '../../properties_business_logic'
import {
    fieldRequired,
    compareDates
} from "@/utils/input_validation.js";
import { ref, onMounted, computed } from 'vue';

const props = defineProps({
    models: {
        type: Object,
        default: null
    },
    generatingReport: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['generateReport'])

const { fetchingAllProperties, fetchAllUserProperties } = useFetchAllUserProperties()

const formRef = ref(null)
async function onGenerateReportFormSubmitted() {
    const formValid = formRef?.value.validate()
    if (formValid) {
        emit('generateReport')
    }
}

const userProperties = ref(null)
onMounted(async () => {
    userProperties.value = await fetchAllUserProperties()
})

const dateGreaterThanFromDate = compareDates({
    otherDateRef: computed(() => props.models.fromDate),
    operator: '>=',
    errorMsg: 'Date must be greater or equal to From Date'
})

const dateLessThanToDate = compareDates({
    otherDateRef: computed(() => props.models.toDate),
    operator: '<=',
    errorMsg: 'Date must be less than or equal to To Date'
})

const additionalDetails = computed(() => {
    let prn = null
    // let lrNumber = null
    const selectedProperty = userProperties.value?.find(property => property.prn === props.models.prn) ?? null
    const selectedPropertyNotEmpty = !!selectedProperty
    if (selectedPropertyNotEmpty) {
        // blockPlotNumber = `${selectedProperty.block_no} / ${selectedProperty.plot_no}`
        // lrNumber = selectedProperty.lr_no
        prn = selectedProperty.prn
    }
    return {
        'PRN': prn,
        // 'LR Number': lrNumber
    }
})

function formatPropertyForDropdown(property) {
    // const prn = property.prn
    // const location = !!property?.physical_location ? ` - ${property.physical_location}` : ''
    // return prn + location
    return `${property.block_no ?? 'N.A'} / ${property.plot_no ?? 'N.A'}`
}
</script>

<template>
    <v-container class="pa-0">
        <v-form ref="formRef" @submit.prevent="onGenerateReportFormSubmitted">
            <v-row>
                <v-col cols="12" md="4">
                    <LabelledField label="Block / Plot Number" required>
                        <v-autocomplete :items="userProperties" :item-text="formatPropertyForDropdown" item-value="prn"
                            outlined dense class="text-field-background" required :loading="fetchingAllProperties"
                            :disabled="fetchingAllProperties" v-model="models.prn" hide-details="auto"
                            :rules="[fieldRequired]"></v-autocomplete>
                    </LabelledField>
                </v-col>

                <v-col cols="12" md="4">
                    <LabelledField label="From Date" required>
                        <DatePicker :rules="[fieldRequired, dateLessThanToDate]" :textFieldProps="{
                            outlined: true,
                            dense: true,
                            class: 'text-field-background',
                            'hide-details': 'auto'
                        }" v-model="models.fromDate" />
                    </LabelledField>
                </v-col>

                <v-col cols="12" md="4">
                    <LabelledField label="To Date" required>
                        <DatePicker :rules="[fieldRequired, dateGreaterThanFromDate]" :textFieldProps="{
                            outlined: true,
                            dense: true,
                            class: 'text-field-background',
                            'hide-details': 'auto'
                        }" v-model="models.toDate" />
                    </LabelledField>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="4" v-for="(value, title) in additionalDetails">
                    <v-skeleton-loader v-if="fetchingAllProperties" type="button" class="skeleton-fill-width">
                    </v-skeleton-loader>

                    <LabelledField v-else :label="title">
                        <v-text-field class="text-field-background" outlined dense hide-details :value="value" disabled>
                        </v-text-field>
                    </LabelledField>
                </v-col>
            </v-row>

            <v-row justify="end">
                <v-col cols="auto">
                    <v-btn depressed color="secondary" class="text-none" type="submit" :loading="generatingReport">
                        Generate Report
                        <v-icon right>mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>